/* eslint-disable max-statements-per-line */
import React from 'react';
const Video = (props) => {
  const { src, ...otherProps } = props;
  let VideoComponent;
  if (props.youtube) {
    VideoComponent = (
      <iframe
        width="100%"
        title="Intro Video"
        src={src}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    );
  }
  VideoComponent = (
    <video
      {...otherProps}
      preload="auto"
      muted
      controls
      autoPlay="autoplay"
      style={{
        height: '100%',
        width: '100%',
        objectFit: 'cover',
      }}
    >
      <source src={src} type="video/mp4" />
    </video>
  );

  return VideoComponent;
};
export default Video;
