import React from 'react';
import { graphql, navigate } from 'gatsby';
// import { isLoggedIn } from 'utils/auth';
import { css } from '@emotion/core';
import FullWidthBox from 'components/FullWidthBox';
import Video from 'components/Video';
import { Button, Typography } from 'antd';
import { AppStateContext } from 'state/appstate';
const Index = () => {
  const { Title } = Typography;
  const { setAppState } = React.useContext(AppStateContext);

  React.useEffect(() => {
    setAppState('nachbarschaft');
  });
  if (typeof window !== 'undefined') {
    window.localStorage.removeItem('state');
  }
  return (
    <FullWidthBox
      css={css`
        height: 100%;
        padding-top: 126px;
        height: calc(100vh - 25px);
      `}
    >
      <article
        css={css`
          display: flex;
          justify-content: center;
          heigth:100%
          flex-direction: column;
        `}
      >
        <Video
          css={css`
            position: absolute;
            right: 0;
            bottom: 0;
            min-width: 100%;
            min-height: 100%;
            width: auto;
            height: auto;
            z-index: -100;
            background-size: cover;
            z-index: 1;
          `}
          src="/video/VideoIntro.mp4"
        />
        <div
          css={css`
            position: absolute;
            right: 100px;
            bottom: 100px;
          `}
        >
          <Button
            size="large"
            // type="primary"

            onClick={() => {
              navigate('/intro/');
            }}
            css={css`
              margin-top: 20px;
              z-index: 2;
            `}
          >
            Video &uuml;berspringen
          </Button>
        </div>
      </article>
    </FullWidthBox>
  );
};
export const query = graphql`
  query Index {
    site: site {
      siteMetadata {
        organization {
          email
          name
          address {
            streetNumber
            zipCity
          }
        }
        social {
          googlemaps
        }
      }
    }
  }
`;

export default Index;
